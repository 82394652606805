import { Navigate } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { Route } from 'react-router-dom'


const PrivateRoute = ({ isAuthenticated, children }) => {


  // only check if user is authenticated after state loads
  if (isAuthenticated) {

    // check if user is authenticated
    if (!isAuthenticated) {
      //if not authenticated, take to login
      return <Navigate to="/login" replace />;
    } else {
      //if authenticated, return component
      return children;
    }
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, {})(PrivateRoute);