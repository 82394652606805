import { useState, React, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/esm/FormSelect';
import FormLabel from 'react-bootstrap/esm/FormLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';


const CreateMeasure = ({ isAuthenticated, access_token, onSubmitForm, error, clearForm, setClearForm}) => {
    // Look into this more 
    const [measure, setMeasure] =  useState({
        "name": '',
        'description': '',
        "type": '',
        "unit":"",
        "allowOther": false,
        "upper_limit": null,
        "lower_limit": null,
        "allow_outside_limit": false,
        "invalid_name": false,
        "category_options": [],
        "allow_multi_select": false
    });
    const [categoryCount, setCategoryCount] = useState(2)
    const [showAlert, setShowAlert] = useState(false)
    useEffect(()=>{
        onSubmitForm(measure)
    },[])
    useEffect(()=>{
        onSubmitForm(measure)
    },[measure])
    useEffect(()=>{
        const timer = setTimeout(() => {
            checkName(measure)
          }, 500)
      
          return () => clearTimeout(timer)
    },[measure.name])
    useEffect(()=>{
        clearState()
        setClearForm(false)
    },[clearForm, setClearForm])
    const clearState = () => {
        setMeasure({...measure, ["name"]: "", ["description"]:"", ["type"]:"",["unit"]:"",["allowOther"]:true,["upper_limit"]:null,["lower_limit"]:null,["allow_outside_limit"]:true,["invalid_name"]:false,["category_options"]:[]})
    }
    const checkName = async () => {
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            }
        }
        const body = {
            "name":measure.name
        }
        try {
            const response =  await axios.post(`${process.env.REACT_APP_API_URL}/labmanager/api/check_measure_exists`, body, config)
            let status = response.data
            
            if(status.exists){
                setShowAlert(true)
                setMeasure({...measure, ["invalid_name"]: true})
            }else{
                setShowAlert(false)
                setMeasure({...measure, ["invalid_name"]: false})
            }
            
        }catch(error){
            console.log(error)
            
        }
    }
    const changeHandler = e => {
        if(e.target.name === "allow_outside_limit" || e.target.name === "allowOther" || e.target.name === "allow_multi_select"){
            setMeasure({...measure, [e.target.name]: e.target.checked})
        }else{
            setMeasure({...measure, [e.target.name]: e.target.value})
        }
        
        
    }
    const handleAddCategory = () => {
        setCategoryCount(categoryCount + 1)
    }

    const handleCategory = (e, key)=>{
        setMeasure(prevState => {
            const updatedCategories = [...prevState.category_options]
            updatedCategories[key] = e.target.value
            return {...prevState, category_options: updatedCategories}
        })
    }
    const handleRemoveCategory = () => {
        if(categoryCount > 2){
            setMeasure(prevState => {
                const updatedCategories = prevState.category_options.slice(0, -1);
                return {...prevState, category_options: updatedCategories}
            })
            setCategoryCount(categoryCount-1)
        }
        

    }
    
    const renderType = () => {
        const inputs = [];
        if(measure.type == "Category"){
            inputs.push(
                <Form.Label className='float-start'>Categories<span style={{color:"red"}}>*</span></Form.Label>
            )
            for(let i = 0; i < categoryCount; i++){
                inputs.push(
                    <Form.Control
                        key={i}
                        name="categories"
                        value= {measure.category_options[i]} 
                        onChange={(e) => handleCategory(e,i)} 
                        type="text"
                        style={{marginBottom:".5em"}}/>
                );
            }
            
            inputs.push(
                <Form.Group >
                    <Button style={{marginRight:".25em"}} onClick={handleAddCategory} type="button" className="btn btn-primary btn-sm">Add</Button>
                    <Button onClick={handleRemoveCategory} type="button" className='btn btn-primary btn-sm'>Remove</Button>
                    {error.categories ? <p style={{color:"red"}}>Please enter a category</p> : null}
                </Form.Group>,
                <Form.Group >
                    <FormLabel style={{marginRight:".25em"}}>Allow Other Response?<span style={{color:"red"}}>*</span></FormLabel>
                    <Form.Check
                        inline
                        style={{marginLeft:".25em"}}
                        type='switch'
                        name='allowOther'
                        onChange={changeHandler}
                    />
                </Form.Group>,
                <Form.Group>
                    <FormLabel style={{marginRight:".25em"}}>Allow Multiple Selections?<span style={{color:"red"}}>*</span></FormLabel>
                    <Form.Check
                        inline
                        style={{marginLeft:".25em"}}
                        type='switch'
                        name='allow_multi_select'
                        onChange={changeHandler}
                    />
                </Form.Group>
                )
            return inputs
        }else if(measure.type == "Continuous"){
            inputs.push(<Form.Group>
                <Row>
                    <Col>
                        <Form.Label className='float-start'>Unit of Measurement</Form.Label>
                        <Form.Control placeholder='Unit of Measure...' name='unit'  onChange={changeHandler} type='text' />
                    </Col>
                    <Col>
                            <FormLabel >Bounds</FormLabel>
                            <Row>
                                <Col>
                                    <Form.Control name='lower_limit' placeholder="Lower Limit" type='number' onChange={changeHandler} />
                                </Col>
                                <Col>
                                    <Form.Control name='upper_limit' placeholder="Upper Limit" type='number' onChange={changeHandler}/>
                                </Col>
                            </Row>
                    </Col>
                </Row>
            {error.upper_limit ? <p style={{color:"red"}}>Please enter an upper limit</p> : null}
            {error.lower_limit ? <p style={{color:"red"}}>Please enter a lower limit</p> : null}
            {error.invalid_limits ? <p style={{color:"red"}}>Please enter valid limits</p> : null}
            {error.unit ? <p style={{color:"red"}}>Please enter a unit</p> : null}
            <FormLabel>Allow Response Outside of Bounds?<span style={{color:"red"}}>*</span></FormLabel>
            <Form.Check
                inline
                style={{marginLeft:".25em", marginTop:".25em"}}
                type='switch'
                name='allow_outside_limit'
                onChange={changeHandler}
            />
            </Form.Group>)
            return inputs
        }
    }
    return (
            <div className="d-flex justify-content-center">  {/* center the form using a flexbox  container */}
                <Form className='w-50'>
                    <fieldset>
                        <Form.Group className="mb-3">
                            <Form.Label className='float-start'>Measure Name<span style={{color:"red"}}>*</span></Form.Label>
                            <Form.Control name='name' onChange={changeHandler} type='text' placeholder='Measure Name...' value={measure.name}/>
                            <Alert show={showAlert} variant={'danger'}>
                                Measure Name already Exists
                            </Alert>
                            {error.name ? <p style={{color:"red"}}>Please enter a name</p> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='float-start'>Description<span style={{color:"red"}}>*</span></Form.Label>
                            <Form.Control as='textarea' name='description' onChange={changeHandler} rows={3} placeholder="Measure Description..." value={measure.description}/>
                            {error.description ? <p style={{color:"red"}}>Please enter a description</p>:null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='float-start'>Measure Type<span style={{color:"red"}}>*</span></Form.Label>
                            <FormSelect value={measure.type} name='type' onChange={changeHandler} aria-label="Default select example">
                                <option value={""}>Select a type</option>
                                <option value={"Continuous"}>Continuous</option>
                                <option value={"Category"}>Categorical</option>
                                <option value={"Text"}>Text</option>
                            </FormSelect>
                            {error.type ? <p style={{color:"red"}}>Please select a type</p>:null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            {renderType()}
                        </Form.Group>
                    </fieldset>
                </Form>
            </div>
        )
};


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});

export default connect(mapStateToProps, { })(CreateMeasure);