import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { signup } from '../../actions/auth';

const Signup = ({ signup, isAuthenticated }) => {
    const [accountCreated, setAccountCreated] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        re_password: ''
    });
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPassword, setInvalidPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const { name, email, password, re_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async (e) => {
        e.preventDefault();
        if(email.includes("@givaudan.com")){
            if (password === re_password) {
                let [status,res] = await signup(name, email, password, re_password);
                if(status){
                    setAccountCreated(true);
                    setInvalidEmail(false);
                    setInvalidPassword(false);
                    setErrorMessage("")
                }else{
                    setInvalidPassword(true);
                    setErrorMessage(res.password.toString())

                }
            }
        }else{
            setInvalidEmail(true);
        }
        
    };

    
    if (isAuthenticated) {
        return <Navigate to='/' replace={true} />
    }

    if (accountCreated) {
        return <Navigate to='/Login' replace={true} />
    }

    return (
        <div className='container mt-5'>
            <h1>Sign Up</h1>
            <p>Create your Account</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group pb-3'>
                <label for="nameField" class="form-label">Full Name</label>
                    <input 
                        id='nameField'
                        className='form-control'
                        type='text'
                        name='name'
                        value={name}
                        onChange={e => onChange(e)}
                        placeholder="Name*"
                        required
                    />
                </div>
                <div className='form-group pb-3'>
                    <label for="emailInput" class="form-label">Email address</label>
                    <input 
                        id='emailInput'
                        className='form-control'
                        type='email'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        placeholder="Email*"
                        required
                    />
                    {invalidEmail ? <p style={{color:"red", fontSize:"small"}}>Invalid email</p> : null}
                </div>
                <div className='form-group pb-3'>
                    <label for="passwordInput" class="form-label">Password</label>
                    <input 
                        id='passwordInput'
                        className='form-control'
                        type='password'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        placeholder="Password*"
                        required
                    />
                    {invalidPassword ? <p style={{color:"red", fontSize:"small"}}>{errorMessage}</p> : null}
                </div>
                <div className='form-group pb-3'>
                    <label for="confirmPasswordInput" class="form-label">Confirm Password</label>
                    <input 
                        id='confirmPasswordInput'
                        className='form-control'
                        type='password'
                        name='re_password'
                        value={re_password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        placeholder="Confirm Password*"
                        required
                    />
                </div>

                <button className='btn btn-primary' type='submit'>Register</button>
            </form>
            <p className='mt-3'>
                Already have an account? <Link to='/login'>Sign In</Link>
            </p>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated
});

export default connect(mapStateToProps, { signup })(Signup);