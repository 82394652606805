import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Select from 'react-select';

const IngredientCard = ({id, ingredients, onAddIngredient, onRemoveIngredient, selectAll}) => {
    const [selectedIngredient, setSelectedIngredient] = useState("")
    const [options, setOptions] = useState([])
    useEffect(()=>{
        let newOptions =[]
        for(let i = 0; i < ingredients.length; i++){
            let temp = {
                value:ingredients[i].id,
                label:ingredients[i].name
            }
            newOptions.push(temp)
        }
        setOptions(newOptions)
    },[])
    useEffect(()=>{
        if(selectedIngredient != ""){
            onAddIngredient(selectedIngredient, id)
        }
    },[selectedIngredient])

    const handleChange = (e) => {
        setSelectedIngredient(e.value)
    }

    return(
        <div>
            <Box>
                <Select
                    options={options}
                    size="small" 
                    isClearable
                    isSearchable
                    isDisabled={selectAll}
                    onChange={(e) => handleChange(e)}
                    styles={{
                        control: (base) => ({
                        ...base,
                        width: '18em',
                        height: '2em',
                        fontSize: '0.875rem',
                        marginLeft:'1em',
                        marginBottom:'.5em' 
                      })
                    }}
                />
            </Box>
        </div>
    )

}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(IngredientCard);