import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

const headerStyle = {
    borderRadius: '10px',
    background: '#e1e1e1',
    padding: '5px'
}

const tableStyle ={
    textAlign: 'center'
}

const TestDetails = ({test,access_token}) => {

    const renderMeasures = () => {
        let measures =[]
        measures.push(
            <Row>
                <Col style={tableStyle}>
                    <b><h6>Name</h6></b>
                </Col>
                <Col style={tableStyle}>
                    <b><h6>Description</h6></b>
                </Col>
                <Col style={tableStyle}>
                    <b><h6>Type</h6></b>
                </Col>
                <Col style={tableStyle}>
                    <b><h6>Required</h6></b>
                </Col>
            </Row>
        )
        for(let i = 0; i < test.measures.length; i++){
            measures.push(
                <Row style={{marginBottom:"1em"}}>
                    <Col style={tableStyle}>
                        {test.measures[i].name}
                    </Col>
                    <Col style={tableStyle}>
                        {test.measures[i].description}
                    </Col>
                    <Col style={tableStyle}>
                        {test.measures[i].type}
                    </Col>
                    <Col style={tableStyle}>
                        {test.measures[i].required ? "Yes": "No"}
                    </Col>
                </Row>
            )
        }
        return measures
    }
    const downloadReference = async () => {
        const headers = {
            'content-type': 'application/json',
            'Authorization': `JWT ${access_token}`,
            'Accept': 'application/json'
        }
        const params = {
            id : test.id,
        }
        try{
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/labmanager/api/download_reference`,{headers, params, responseType: 'blob'});
            const disposition = response.headers.get('Content-Disposition');
            var fileName = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            if (fileName.toLowerCase().startsWith("utf-8''"))
                fileName = decodeURIComponent(fileName.replace("utf-8''", ''));
            else
                fileName = fileName.replace(/['"]/g, '');
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a); // append the element to the dom
            a.click();
            a.remove(); // afterwards, remove the element
        }catch(error){
            console.log(error)
        }
    }
    return(
        <div>
            <div className='d-flex flex-row sm'>
                <Row style={{width:'100%'}}>
                    <Col>
                        <h5 style={headerStyle}>Test Name</h5>
                        <p>{test.name}</p>
                    </Col>
                    <Col>
                        <h5 style={headerStyle}>Test Owner</h5>
                        <p>{test.creator}</p>
                    </Col>
                    <Col>
                        <h5 style={headerStyle}>Version</h5>
                        <p>{test.version_num}</p>
                    </Col>
                    <Col>
                        <h5 style={headerStyle}>Reference Methods</h5>
                        <button onClick={downloadReference}>Download</button>
                    </Col>
                </Row>
            </div>
            <div>
                <h5 style={headerStyle}>Test Description</h5>
                <p>{test.description}</p>
            </div>
            <div style={{marginLeft:"20%", marginRight:"20%", padding:"10px"}}>
                {renderMeasures()}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(TestDetails);