import React, { useState, setState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import AddTests from './AddTests'
import IngredientInformation from './components/IngredientInformation';
import axios from 'axios';
import "./IngredientViewStyle.css";
import ViewTests from './ViewTests';

const IngredientView = ({access_token}) => {
    //TODO: Add type param and replace props on tab components
    const { uuid, type } = useParams();
    const [ingredientInfo, setIngredientInfo] = useState({})
    const [activeTab, setActiveTab] = useState(0);
    useEffect(()=>{
        const getMaterialInformation = async () =>{
            const headers = {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            }
            const params = {
                id : uuid,
                ingredient_formula: type
            }
            try{
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/formula_ingredient/api/material_information`,{headers, params});
                await setIngredientInfo(response.data.result)
            }catch(error){
                console.log(error)
            }
        }
        getMaterialInformation();
        
    },[])
    
    
    const tabs = [
        {label: "View Tests", component:<ViewTests id={uuid} type={type}/>},
        {label: "Add Data", component: <AddTests id={uuid} type={type}/> }
    ];
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    return(
        <div>
            <div style={{margin:"2%"}}>
                    <IngredientInformation  ingredient={ingredientInfo}/>
            </div>
            <div style={{margin:"2%"}} className="tab-buttons">
                {tabs.map((tab, index) => (
                    <button
                    key={index}
                    className={`tab-button ${index === activeTab ? 'tab-button-active' : ''}`}
                    onClick={() => handleTabClick(index)}
                    >
                    {tab.label}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {tabs[activeTab].component}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(IngredientView);