import React, { useState, setState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
const Continuous = ({measure, onSubmitForm, error}) => {
    const [measureValue, setMeasureValue] = useState("")

    useEffect(()=>{
        let in_limit = true
        if(measure.lower_limit !== null && measure.upper_limit !== null){
            if( measureValue < measure.lower_limit || measureValue > measure.upper_limit){
                in_limit = false
            }
        }
        var data = {
            "measure_name": measure.name,
            "measure_type": "Continuous",
            "result": parseInt(measureValue),
            "in_limit": in_limit
        }
        onSubmitForm(data)
    },[measureValue])
    const changeHandler = (e)=>{
        setMeasureValue(e.target.value)
    }
    return(
        <div>
            <h6>{measure.name}{measure.unit !== "" ? <span>({measure.unit})</span> : null}{measure.required ? <span style={{color:"red"}}>*</span> : null}</h6>
            <Box sx={{ width:"15vw", height:"12vh"}}>
                <TextField 
                    id="standard-basic" 
                    label="Enter Measure" 
                    variant="standard" 
                    type="number" 
                    size="small"
                    onChange={changeHandler} 
                    value={measureValue}
                    style={{width:"100%"}}/>
                {error.bounds ? <p style={{color:"red", fontSize:"small"}}>Please enter a measure within the limits</p> : null}
                {error.required ? <p style={{color:"red", fontSize:"small"}}>This measure is required</p> : null}
            </Box>
        </div>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(Continuous);