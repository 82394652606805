import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2'; 

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const CompareCharts = ({ingredientData, selectAll}) => {
    const [chartData, setChartData] = useState([])
    const [measures, setMeasures] = useState([])

    useEffect(()=>
    {
        
        const getChartData = () =>
        {
            const tempMeasures = []
            for(let i = 0; i < ingredientData.length; i++)
            {
                if('measures' in ingredientData[i])
                {
                    for(let j = 0; j < ingredientData[i].measures.length; j++)
                    {
                        if(!tempMeasures.includes(ingredientData[i].measures[j].measure_name))
                        {
                            if(ingredientData[i].measures[j].measurement_type == "Continuous")
                            {
                                tempMeasures.push(ingredientData[i].measures[j].measure_name)
                            }
                        }
                    }
                }
                
            }
            if(!selectAll){
                let tempChartData = []
                for(let i = 0; i < tempMeasures.length; i++)
                {
                    let tempMeasure = {}
                    let tempIngredients = []
                    for(let j = 0; j < ingredientData.length; j++)
                    {
                        if('measures' in ingredientData[j])
                        {
                            for(let k = 0; k < ingredientData[j].measures.length; k++)
                            {
                                if(ingredientData[j].measures[k].measure_name == tempMeasures[i])
                                {
                                    let temp = 
                                    {
                                        ingredient_name: ingredientData[j].name,
                                        result: ingredientData[j].measures[k].result
                                    }
                                    tempIngredients.push(temp)
                                }
                            }
                        }
                        
                    }
                    tempMeasure = 
                    {
                        measure_name: tempMeasures[i],
                        ingredients: tempIngredients
                    }
                    tempChartData.push(tempMeasure)
                }
                setChartData(tempChartData)
            }else
            {
                setChartData([])
            }
            
        }
        getChartData()

    },[ingredientData])
    
    // const getRandomColor = () => {
    //     const r = Math.floor(Math.random() * 256);
    //     const g = Math.floor(Math.random() * 256);
    //     const b = Math.floor(Math.random() * 256);
    //     const alpha = 0.6; // You can adjust the alpha (transparency) value if needed
    //     return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    // };

    const options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
    };
    const buildCharts = () => {
        let charts =[]
        if(chartData.length > 0){
            for(let i =0; i < chartData.length; i++)
            {
                let tempIngredients = []
                let tempResults = []
                for(let j = 0; j < chartData[i].ingredients.length;j++)
                {
                tempIngredients.push(chartData[i].ingredients[j].ingredient_name)
                tempResults.push(chartData[i].ingredients[j].result)
                }
                let tempData = {
                    labels: tempIngredients,
                    datasets: 
                    [{
                        label: chartData[i].measure_name,
                        data:tempResults,
                        backgroundColor: 'rgba(53, 162, 235, 0.5)' //Array.from({ length: tempIngredients.length }, () => getRandomColor())
                    }]
                }
                charts.push(
                    <div style={{width:"30%", marginLeft:'2%'}}>
                        <Bar data={tempData} options={options} />
                    </div>
                )
            }
            return charts
        }else
        {
            return <></>
        }
        
        
    }

    return(
        <>
            <div className='d-flex flex-row sm' style={{margin:"auto", flexWrap:'wrap'}}>
                {buildCharts()}
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(CompareCharts);