import React, { useState, setState, useEffect } from 'react';
import AddTest from './components/AddTest';
import { connect } from 'react-redux';
import { Select, MenuItem, InputLabel } from '@mui/material';
import Button from 'react-bootstrap/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Swal from 'sweetalert2'

const AddTests = ({access_token, id, type}) =>{
    const [activeTests, setActiveTests] = useState([])
    const [inactiveTests, setInactiveTests] = useState([])
    const [selectedTest, setSelectedTest] = useState("")
    const [testData, setTestData] = useState({
        "ingredient_id":null,
        "formula_id":null,
        "test_results": []
    })
    const [errorData, setErrorData]= useState([])
    useEffect(() => {
        const fetchTests = async ()=> {
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
            }
            const body = ""
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/labmanager/api/get_tests`, body ,config)
                await setInactiveTests(response.data.tests)
            }catch(error){
                console.log(error)
            }
        }
        fetchTests();
    },[])
    const addErrors = (activeTestsIn) =>{
        let test_errors = []
        for(var i = 0; i <activeTestsIn.length; i++){
            let measures = []
            for(var j = 0; j< activeTestsIn[i].measures.length; j++){
                if(activeTestsIn[i].measures[j].type == "Continuous"){
                    let measure_error = {
                        "name": activeTestsIn[i].measures[j].name,
                        "required": false,
                        "bounds": false
                    }
                    measures.push(measure_error)
                }else{
                    let measure_error = {
                        "name": activeTestsIn[i].measures[j].name,
                        "required": false
                    }
                    measures.push(measure_error)
                }
                
            }
            let test_error_single = {
                "name":activeTestsIn[i].name,
                "measure":measures
            }
            test_errors.push(test_error_single)
        }
        setErrorData(test_errors)
    }
    const selectTestHandler = (e)=>{
        setSelectedTest(e.target.value)
    }
    const submitForm = (test)=>{
        const selected = testData.test_results.findIndex(testData => testData.test_name === test.test_name);
        let formulaId = null
        let ingredientId = null
        if(type == "ingredient"){
            ingredientId = id
        }else{
            formulaId = id
        }
        let newData =[]
        if(selected !== -1){
            newData = [...testData.test_results]
            newData[selected].measures = test.measures
        }else{
            newData = [...testData.test_results, test]
        }
        setTestData({
            "ingredient_id":ingredientId,
            "formula_id":formulaId,
            "test_results": newData
        })
    }
    const renderTests = () => {
        let inputs = []
        for(var i = 0; i < activeTests.length; i++){
            inputs.push(
                <AddTest onSubmitForm={submitForm} errors={errorData[i]} test={activeTests[i]}/>,
                <hr/>
            )
        }
        return inputs
    }
    
    const checkBounds = (measure, submission) => {
        return !measure.allow_outside_limit && (submission.result < measure.lower_limit || submission.result > measure.upper_limit);
    };
    const checkRequired = (measure, submission) => {
        return measure.required && (submission.result === "" || submission.result === null);
    };
    const setError = (errorData, name, measure, field, value) => {
        const testIndex = errorData.findIndex(error => error.name === name);
        const measureIndex = errorData[testIndex].measure.findIndex(error => error.name === measure.name);
        errorData[testIndex].measure[measureIndex][field] = value;
        return errorData;
    };

    const validateContinuous = (errorData, name, measure, submission) => {
        let outsideError = checkBounds(measure, submission);
        let requiredError = checkRequired(measure, submission);
        let newErrorData = [...errorData];

        newErrorData = setError(newErrorData, name, measure, 'bounds', outsideError);
        newErrorData = setError(newErrorData, name, measure, 'required', requiredError);

        setErrorData(newErrorData);
        return outsideError || requiredError;
    };
    
    const validateOther = (errorData, name, measure, submission) => {
        let requiredError = checkRequired(measure, submission);
        let newErrorData = [...errorData];

        newErrorData = setError(newErrorData, name, measure, 'required', requiredError);

        setErrorData(newErrorData);
        return requiredError;
    };

    const validate = () => 
    {
        let errors = [];
        let newErrorData = [...errorData];
        activeTests.forEach(activeTest => {
            const selectedTest = testData.test_results.find(test => test.test_name === activeTest.name);
            if (selectedTest) 
            {
                activeTest.measures.forEach(measure => 
                {
                    const selectedMeasure = selectedTest.measures.find(m => m.measure_name === measure.name);
                    console.log(selectedTest.measures)
                    console.log(measure.name)
                    if (!selectedMeasure)
                    {
                        newErrorData = setError(newErrorData, activeTest.name, measure, 'required', true);
                        errors.push(true)
                    }else if (selectedMeasure.measure_type === "Category") 
                    {
                        if (measure.required && (selectedMeasure.result.length === 0 || !selectedMeasure))
                        {
                            newErrorData = setError(newErrorData, activeTest.name, measure, 'required', true);
                            errors.push(true)
                        }
                        else
                        {
                            let error = false;
                            if (selectedMeasure) 
                            {
                                error = measure.type === "Continuous"
                                    ? validateContinuous(newErrorData, activeTest.name, measure, selectedMeasure)
                                    : validateOther(newErrorData, activeTest.name, measure, selectedMeasure);
                            }
                            errors.push(error);
                        }
                    }
                    else 
                    {
                        if(measure.required && (isNaN(selectedMeasure.result) || !selectedMeasure))
                        {
                            newErrorData = setError(newErrorData, activeTest.name, measure, 'required', true);
                            errors.push(true)
                        }
                        else
                        {
                            let error = false;
                            if (selectedMeasure) 
                            {
                                error = measure.type === "Continuous"
                                    ? validateContinuous(newErrorData, activeTest.name, measure, selectedMeasure)
                                    : validateOther(newErrorData, activeTest.name, measure, selectedMeasure);
                            }
                            errors.push(error);
                        }
                    }
                });
            }
        });
        setErrorData(newErrorData);
        return !errors.includes(true);
    };
    const submitTests = async (e) => {
        e.preventDefault()
        if(activeTests.length > 0){
            var valid = validate()
            if(valid){
                const config = {
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `JWT ${access_token}`,
                        'Accept': 'application/json'
                    }
                }
                const body = testData
                try{
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/labvisitor/api/save_test_results`,JSON.stringify(body) ,config)
                    Swal.fire({
                        title: "Success",
                        text: "Your data has been added",
                        icon: 'success',
                        confirmButtonText: "Done",
                        confirmButtonColor: '#198754'
                    }).then((result) =>{
                        if(result.isConfirmed){
                            window.location.reload()
                        }
                    })
                    
                }
                catch(error){
                    console.log(error)
                }

            }
        }
        
    }
    const addTestHandler = async () =>{
        const selected = inactiveTests.find(test => test === selectedTest);
        if(selected){
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
            }
            const body = {
                "test_name": selectedTest
            }
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/labvisitor/api/get_test_measures`,JSON.stringify(body) ,config)
                const new_test = {
                    "name":response.data.test,
                    "measures": response.data.measures
                }
                setInactiveTests(inactiveTests.filter(test => test !== selectedTest));
                setActiveTests([...activeTests, new_test]);
                addErrors([...activeTests, new_test])
                setSelectedTest("");
            }catch(error){
                console.log(error)
            }
            
            
        }
    }
    return(
        <div>
            <hr/>
            <div className='center mt-3 mb-3 text-center'>
                <h2>Add Test Data</h2>
                <div>
                    {renderTests()}
                </div>
                <div className='d-flex flex-row sm justify-content-center'>
                    <InputLabel 
                        id="select-test-label" 
                        sx={{ display: 'flex', alignItems: 'center', marginRight:".5em"}}
                        >
                            Add a test
                        </InputLabel>
                    <Select
                        labelId="select-test-label"
                        onChange={selectTestHandler}
                        style={{height:"2.5em", width:"7em"}}
                    >
                        {inactiveTests.map((option,i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    <IconButton aria-label="add" onClick={addTestHandler}>
                            <AddIcon/>
                    </IconButton>
                </div>
                <hr/>
                <Button onClick={submitTests} className="btn btn-success btn-md float-start">Submit</Button>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(AddTests);