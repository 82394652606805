import React, { useState } from 'react';
import { Link, redirect, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import { Button } from '@mui/material';

const Dashboard = () => {

        return (

            <div className='container-md pt-4'>
                <h1 class="display-2 pb-3 text-center">Welcome to IDG 2.0</h1>
                <p>IDG-2.0 is a prototype application that aims to expand beyond flavor data. It establishes a centralized platform for storing and managing ingredient-level data for protein and health & wellness applications, surpassing Excel's capabilities. This platform is tailored to the needs of the applications team, offering advanced functionalities. IDG 2.0 looks beyond the horizon of flavor and focused on ingredient data that can build capabilities to support the Smart Solutions stream under the NPD program. IDG-2.0 builds on the capabilities of IDG-1.0. IDG 1.0 provided integration with AKB, MDM, Athena, Ingredient Alternatives, Formula Compare Module. It has successfully been leveraged to develop and build the Flavor Compare Module which is now used by portfolio selection to match a flavor recommendation to a brief. IDG-2.0 includes several key features that mark a shift from IDG 1.0:
                </p>
                <ul>
                    <li>Coded and Non-Coded Ingredients: Ability to add test results for non-coded materials is now supported</li>
                    <li>Flexible, Expandable, and Democratized Data: Users can expand the data we capture by defining new tests/measures. Additionally, a user can now add data to a ingredient with no restrictions.</li>
                    <li>Leverages machine-actionable data by data scientists</li>
                </ul>
                <p class='pb-3'>The application is currently in prototype stage. Your feedback will be critical in shaping the capability and functionality of this application. Please provide it here: include link. In the sections below you can gain an understanding of the functionality and features available in this prototype.</p>



                <div class="jumbotron pb-3">
                    <h2>Ingredients</h2>
                    <p>Every test or attribute is ultimately linked to an ingredient. In the search bar above, you can use the dropdown to select either "Ingredient"" Type in the name of the ingredient you are searching and be presented with a list of names that match your query. To expand to non-flavor and non-coded ingredients, several sources of data were compiled to bring in more ingredients and formulas into IDG-2.0 including: </p>
                    <ul>
                        <li>Global Protein DB: Expert maintained list of proteins including mostly non-coded ingredients from various suppliers. (93 protein ingredients)</li>
                        <li>IDG 1.0: All protein ingredients in IDG 1.0 are available to annotate and add data with IDG 2.0 (200 ingredients)</li>
                        <li>H&N: Botanical ingredients for Health and Nutrition applications from First Selection Sheet in Up to Speed Project (239 ingredients) </li>
                    </ul>
                </div>

                <div class="jumbotron pb-3">
                    <h2>Add Tests and Measures</h2>
                    <p>IDG 2.0 is our foray into inviting users in to add data to ingredients. After searching and finding the ingredient of interest from the search bar, you are presenting with the option to view test data or additionally add data. In the "View Tests" tab you can see results from other testers. In the "Add Data" tab, you can search and find a test of interest to associate with the ingredient. Selecting the test creates measures for the fields associated to the test. A test can be thought of as a procedure or operation and the measures are the exact points of data collected from it. For example, we may investigate the test CIEL*a*b* and the measures from this color analysis would be L*, a*, b*, and mode of measurement (reflectance/transmittance). This flexibility gives us the ability to use any test across any ingredient, and additionally the flexibility to structure tests in an intuitive and useful way.</p>
                </div>

                <div class="jumbotron pb-3">
                    <h2>Create Tests and Measures</h2>
                    <p>An additional capability of IDG 2.0 is the evolvability of our data. Testing programs evolve and the data that brings most valuable to the organization will continue to change. The Create Tests and Measures capabilities are focused on matching the changing needs of Givaudan. It enables data admin users the ability to define and create new tests that any user can instantly use and start adding to ingredients. Feel free to create new tests and measures using the links below:</p>
                    <ul>
                        <li>
                            <Link to='/create_measure'>Create a Measure</Link>
                        </li>
                        <li>
                            <Link to='/create_test'>Create a Test</Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    };

export default Dashboard;