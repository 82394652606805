import React, { useState, setState, useEffect } from 'react';
import TestDetails from './components/TestDetails';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';

const TestGlossary = ({access_token}) => {
    const [tests, setTests] = useState([])
    const [searchValue, setSearchValue] = useState("")
    useEffect(()=>{
        getTestGlossary()
    },[])

    const  getTestGlossary = async () => {
        if(searchValue == "")
        {
            const headers = {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            }
            try{
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/labmanager/api/testing_glossary`,{headers});
                await setTests(response.data.glossary)
            }catch(error){
                console.log(error)
            }
        }
        else
        {
            const headers = {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            }
            
            const params = {
                search_value: searchValue
            }
            try{
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/labmanager/api/test_glossary_search`, {headers, params});
                await setTests(response.data.glossary)
            }catch(error){
                console.log(error)
            }
        }
        
    }
    useEffect(()=>{
        const timer = setTimeout(() => {
            getTestGlossary()
          }, 500)
      
          return () => clearTimeout(timer)
    },[searchValue])

    const onChange = (event) => {
        setSearchValue(event.target.value)
    }

    const renderTests = () => {
        let inputs = []
        for(let i = 0; i < tests.length;i++){
            inputs.push(
                <TestDetails test={tests[i]}/>,
                <hr/>
            )
            
        }
        return inputs
    }

    return(
        <div style={{margin:"2em"}}>
            <h2 style={{ textAlign: 'center' }}>Givaudan Testing Glossary</h2>
            <hr/>
            <div style={{marginBottom:".5em"}}>
                <TextField 
                    id="outlined-basic" 
                    label="Search Test " 
                    variant="outlined"
                    onChange={onChange} 
                />
            </div>
            <div>
                {renderTests()}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(TestGlossary);