import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import ViewMeasure from './ViewMeasure';

const ViewTest = ({test}) => {

    return(
        <div className='d-flex flex-row sm'>
            <div className='d-flex justify-content-center'>
                <Box sx={{p: 1, paddingRight: "2em"}}>
                    <h6>{test.test_name}</h6>
                    <p>Added by {test.tester}</p>
                    <p>{test.timestamp_added}</p>
                </Box>
            </div>
            <div>
                <ViewMeasure measures={test.measures}/>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    access_token: state.auth.access
});
export default connect(mapStateToProps, {})(ViewTest);