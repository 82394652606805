import React, { Fragment, useState, useRef } from 'react';
import { Link, Navigate, useNavigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import performance_logo from '../assets/performance_icon.png';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { MenuItem } from '@mui/material';
import { debounce } from 'lodash';

const Navbar = ({ logout, isAuthenticated, access_token }) => {  
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchDomain, setSearchDomain] = useState("ingredient")
    const navigate = useNavigate();
    const domain_options = [
        { value: 'ingredient', label: 'Ingredient' }
      ]
    const handleChange = async (selectedOption, action) => {
        if(selectedOption){
            navigate(`/${searchDomain}/${selectedOption.value}`);
            window.location.reload()
        }
        // perform the get request for the selected option
        // and take the user to the proper URL based on the uuid
    }
    let timer = useRef();
    const handleSearch = (inputValue) => {
        return new Promise((resolve)=>{
            clearTimeout(timer.current);
            timer.current = setTimeout(async () => {
                const headers = {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
                const params = {
                    search_term : inputValue,
                    search_domain: searchDomain
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/formula_ingredient/api/search`,{headers, params});
                let result = response.data.result
                let searchOptions =[]
                if(result.length > 0 ){
                    for(let i = 0; i < result.length;i++){
                        let temp = {
                            value: result[i].id,
                            label: result[i].name,
                        }
                        searchOptions.push(temp)
                    }
                }
                resolve(searchOptions);
            },500)
            //perform the request to get the list of potential options
        })
      }


    const handleLogout = () => {
        logout();
      };


      if (!isAuthenticated) {

        return (
            <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <img classs="img-responsive" height='50px' alt='performance logo'  src={performance_logo}></img>
            <Link className='navbar-brand ms-5' to='/'>IDG 2.0: Login</Link>
            <button 
                className='navbar-toggler' 
                type='button' 
                data-toggle='collapse' 
                data-target='#navbarNav' 
                aria-controls='navbarNav' 
                aria-expanded='false' 
                aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
                <ul className='navbar-nav'>
                    <li className='nav-item active'>
                        <Link className='nav-link' to='/'>Home<span className='sr-only'></span></Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to='/login'>Login</Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to='/signup'>Sign Up</Link>
                    </li>
                    
                </ul>
            </div>
        </nav>
        ) 
      } else {
        // this is what we display for logged in users
        return (
            <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <img classs="img-responsive" height='50px' alt='performance logo'  src={performance_logo}></img>
            <Link className='navbar-brand ms-5' to='/dashboard'>IDG 2.0</Link>
            <button 
                className='navbar-toggler' 
                type='button' 
                data-toggle='collapse' 
                data-target='#navbarNav' 
                aria-controls='navbarNav' 
                aria-expanded='false' 
                aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
                <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                    <li className='nav-item active'>
                        <Link className='nav-link' to='/dashboard'>Dashboard<span className='sr-only'></span></Link>
                    </li>
                    <li>
                        <Link className='nav-link' to='/test_glossary'>Test Glossary<span className='sr-only'></span></Link>
                    </li>
                    <li>
                        <Link className='nav-link' to='/compare_ingredients'>Compare</Link>
                    </li>
                    <li className='nav-item'>
                        
                    </li>
                    <li className='nav-item'>
                        <AsyncSelect
                            loadOptions={handleSearch}
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Search for an ingredient"
                            onChange={(e,action) => handleChange(e,action)}
                            styles={{
                                control: (base) => ({
                                ...base,
                                width: '25em',
                                marginLeft:'.5em' // set the width of the control element
                              })
                            }}
                        />
                    </li>
                    
                </ul>
                <ul className='navbar-nav'>
                    <li className='nav-item'>
                        <Link className='nav-link' onClick={handleLogout} >Logout</Link>
                    </li>
                </ul>
            </div>
        </nav>
        ) 
      }
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});

export default connect(mapStateToProps, { logout })(Navbar);