import React from 'react';


const Home = () => (
    <div className='container-md pt-4'>
        <div class="jumbotron">
            <h1 class="display-4">IDG 2.0</h1>
            <p class="lead">Building our digital foundation to win.</p>
            <p>This is concept application create new tests and measures as well as capture data for ingredient.</p>
            <hr class="my-4" />
            <p>Click the login button to get started</p>
            <p class="lead">
                <a class="btn btn-primary btn-lg" href="/login" role="button">Login</a>
            </p>
        </div>
    </div>
);

export default Home;