import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';

const IngredientInformation = ({ingredient}) => {
    return(
        <div style={{fontSize:"large"}}>
            <Row>
                <Col>
                Ingredient Name: {ingredient.name}
                </Col>
                <Col>
                Ingredient Category: {ingredient.ingredient_category}
                </Col>
            </Row>
            <Row>
                <Col>
                Material Code: {ingredient.material_number}
                </Col>
                <Col>
                Coded: {ingredient.coded ? "True" : "False"}
                </Col>
            </Row>
            <Row>
                <Col>
                Description: {ingredient.description}
                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(IngredientInformation);