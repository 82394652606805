import { useState, React } from 'react';
import CreateMeasure from '../CreateMeasure'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'


const SubmitMeasure = ({ isAuthenticated, access_token }) => {
    const [formData, setFormData] = useState({})
    const [invalidName, setInvalidName] = useState(false)
    const [resetForm, setResetForm] = useState(false)
    const [errors, setErrors] = useState({
        "name": false,
        "description": false,
        "type": false,
        "unit": false,
        "upper_limit": false,
        "lower_limit": false,
        "categories": false,
        "invalid_limits": false
    })
    // Missing allow other
    
    const handleSubmitForm = (data) => {
         let data_formatted = {
            "measure_information":{
                "name":data.name,
                "description":data.description,
                "type": data.type
            },
            "measure_details": {
                "unit": data.unit,
                "upper_limit": data.upper_limit,
                "lower_limit": data.lower_limit,
                "allow_outside_limit": data.allow_outside_limit,
                "category_options": data.category_options,
                "allow_other":data.allowOther,
                "allow_multi_select": data.allow_multi_select
            }
        }
        setInvalidName(data.invalidName)
        setFormData(data_formatted);
    }
    const validateForm = () => {
        var name_in, description_in, type_in, unit_in, upper_limit_in, lower_limit_in, categories_in, invalid_in = false
        if(invalidName){
            name_in = true
        }
        if(formData.measure_information.name === ""){
            name_in = true
        }
        if(formData.measure_information.description === ""){
            description_in= true
        }
        if(formData.measure_information.type === ""){
            type_in = true
        }
        if(formData.measure_information.type === "Continuous"){
            if(formData.measure_details.upper_limit !== null || formData.measure_details.lower_limit !== null){
                if(formData.measure_details.upper_limit === null){
                    upper_limit_in = true
                }
                if(formData.measure_details.lower_limit === null){
                    lower_limit_in = true
                }
                if(parseInt(formData.measure_details.lower_limit) > parseInt(formData.measure_details.upper_limit)){
                    invalid_in = true
                }
            }
        }
        if(formData.measure_information.type === "Categorical"){
            if(formData.measure_details.category_options.length < 2){
                categories_in = true
            }else{
                for(let i = 0; i < formData.measure_details.category_options.length; i++){
                    if(formData.measure_details.unit[i] == ""){
                        categories_in = true
                        break
                    }
                }
            }
        }
        setErrors({...errors, ["name"]: name_in, ["description"]: description_in,["type"]:type_in, ["unit"]:unit_in,["upper_limit"]:upper_limit_in,["lower_limit"]:lower_limit_in,["categories"]:categories_in,["invalid_limits"]:invalid_in})
        if(name_in || description_in || unit_in || upper_limit_in || lower_limit_in || categories_in || invalid_in || type_in){
            return false
        }else{
            return true
        }
    }
    const handleSubmit = async (e) =>{
        e.preventDefault()
        let valid = validateForm()
        if(valid){
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
            }
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/labmanager/api/create_measure`, formData, config)
                Swal.fire({
                    title: "Success",
                    text: "Your measure has been added",
                    icon: 'success',
                    confirmButtonText: true,
                    confirmButtonColor: '#198754',
                    showCancelButton: true,
                    cancelButtonColor: "#198754",
                    confirmButtonText: 'Go Home',
                    cancelButtonText: `Save New Measure`,
                }).then((result) =>{
                    if(result.isConfirmed){
                        window.location.href = '/dashboard';
                    }else if(result.isDismissed){
                        setResetForm(true)
                    }
                    
                })
            }catch(error){
                console.log(error)
                Swal.fire({
                    title:"Error Adding Measure",
                    text: "Error uploading measure please try again",
                    icon:'error'
                })
            }
        }       
    }
    
    return(
        <div className='center mt-3 mb-3 text-center'>
            <h1>Create Your Measure</h1>
            <CreateMeasure error={errors} onSubmitForm={handleSubmitForm} clearForm={resetForm} setClearForm={setResetForm}/>
            <Button style={{marginLeft:"25%"}} type="submit" onClick={handleSubmit} className="btn btn-success btn-md float-start">Submit</Button>
        </div>
    )
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});

export default connect(mapStateToProps, { })(SubmitMeasure);