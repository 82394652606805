import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

const Categorical = ({measure, onSubmitForm, error}) => {
    const [selectedOption, setSelectedOption] = useState([])
    const optionChangeHandler = (selectedVal) => {
        setSelectedOption(selectedVal)
    }
    useEffect(()=>{
        let match_list = true
        if(measure.category_options.includes(selectedOption)){
            match_list = true
        }else{
            match_list = false
        }
        var data = {
            "measure_name": measure.name,
            "measure_type": "Category",
            "result": selectedOption,
            "match_list": match_list
        }
        onSubmitForm(data)
    },[selectedOption])
    
    const renderInput = () => {
        if(measure.allow_other){
            return(
                <div>
                    <Autocomplete
                        freeSolo
                        multiple={measure.allow_multi_select}
                        labelId="auto-category-label"
                        options={measure.category_options.map((option)=>option)}
                        value={selectedOption}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        onChange={(e,newValue) => optionChangeHandler(newValue)}
                        renderInput= {(params) => (
                            <TextField
                                {...params}
                                label="Select Measure"
                                InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                }}
                            />
                            )}
                        disableClearable={true}
                    />
                </div>
            )
        }else{
            return(
                <div>
                    <Autocomplete
                        multiple={measure.allow_multi_select}
                        labelId="category-label"
                        options={measure.category_options.map((option)=>option)}
                        value={selectedOption}
                        onChange={(e,newValue) => optionChangeHandler(newValue)}
                        renderInput= {(params) => (
                            <TextField
                                {...params}
                                label="Select Measure"
                                InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                }}
                            />
                            )}
                        disableClearable={true}
                    />
                </div>
            )
        }
    }

    return(
        <div>
            <h6>{measure.name}{measure.required ? <span style={{color:"red"}}>*</span> : null}</h6>
            <Box sx={{ p: 1,width:"20vw", height:"12vh"}}>
                {renderInput()}
                {error.required ? <p style={{color:"red", fontSize:"small"}}>This measure is required</p> : null}
            </Box>
        </div>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(Categorical);