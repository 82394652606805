import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
const Text = ({measure, onSubmitForm, error}) => {
    const [measureValue, setMeasureValue] = useState("")
    useEffect(()=>{
        if(measureValue !== ""){
            const data = {
                "measure_name": measure.name,
                "measure_type": "Text",
                "result": measureValue
              };
              onSubmitForm(data);
        }
        
    },[measureValue])
    
    const changeHandler = (e) => {
        setMeasureValue(e.target.value)
    }
    return(
        <div>
            <h6>{measure.name}{measure.required ? <span style={{color:"red"}}>*</span> : null}</h6>
            <Box sx={{width:"28vw", height:"12vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}} 
                textAlign="center" 
                display="flex" 
                justifyContent="center">
                <TextField
                    id="outlined-multiline-flexible"
                    label="Text Measure"
                    multiline
                    onChange={changeHandler}
                    rows={1}
                    size="small"
                    value={measureValue}
                    style={{height:"80%", width:"95%"}}
                    />
                    
            </Box>
            {error.required ? <p style={{color:"red", fontSize:"small"}}>This measure is required</p> : null}
        </div>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(Text);