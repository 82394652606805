import React, { useState, setState, useEffect } from 'react';
import { Link, redirect, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/esm/FormSelect';
import FormLabel from 'react-bootstrap/esm/FormLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const CreateTest = ({ isAuthenticated, access_token, onSubmitForm, measureOptions, error,clearForm, setClearForm}) => {

    const [test, setTest] = useState({
        "name": "",
        "description": "",
        "ingredient": false,
        "application": false,
        "category": "",
        "measures": [],
        "required":[],
        "for_idg": false,
        "documents": null
    })     
    const [measureCount, setMeasureCount] = useState(1)
    useEffect(()=>{
        onSubmitForm(test)
    },[test])
    useEffect(()=>{
        clearState()
        setClearForm(false)
    },[clearForm, setClearForm])
    const clearState = () => {
        setTest({...test, ["name"]:"",["description"]:"",["ingredient"]:false,["application"]:false,["category"]:"",["measures"]:[],["required"]:[]})
        setMeasureCount(1)
    }
    const handleChange = e =>{
        if(e.target.name == "ingredient" || e.target.name == "application"){
            setTest({...test, [e.target.name]: e.target.checked})
        }else{
            setTest({...test, [e.target.name]: e.target.value})
        }
        
    }
    const handleMeasure = (e, newValue,key)=>{
        setTest(prevState => {
            const updatedMeasures = [...prevState.measures]
            updatedMeasures[key] = newValue
            return {...prevState, measures: updatedMeasures}
        })
    }
    const handleRequiredMeasure = (e, key)=>{
        setTest(prevState => {
            const updatedRequired = [...prevState.required]
            updatedRequired[key] = e.target.checked
            return {...prevState, required: updatedRequired}
        })
    }
    const handleRemoveMeasure = () => {
        if(measureCount > 1){
            setTest(prevState => {
                const updatedMeasures = prevState.measures.slice(0, -1);
                return {...prevState, measures: updatedMeasures}
            })
            setMeasureCount(measureCount-1)
        }
        

    }
    const handleAddMeasure = () => {
        setMeasureCount(measureCount + 1)
    }

    const handleFileChange = (event) => {
        setTest({...test, ["documents"]: event.target.files[0]})
    }
    const renderMeasure = () => {
        const inputs = []
        inputs.push(
            <Row>
                <Col>Measure</Col>
                <Col>Required</Col>
            </Row>
        )
        for(let i = 0; i < measureCount; i++){
            inputs.push(
                <Row>
                    <Col>
                        <Autocomplete
                        autoComplete
                        key={i}
                        value={test.measures[i]}
                        style={{marginBottom:".5em"}}
                        options={measureOptions.map((option)=>option)}
                        onChange={(e,newValue) => handleMeasure(e,newValue,i)} 
                        renderInput= {(params) => (
                            <TextField
                                {...params}
                                label="Type Measure"
                                InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                }}
                            />
                            )}
                        />                    
                    </Col>
                    <Col>
                        <Form.Check 
                            type={"checkbox"}
                            key={i}
                            onChange={(e)=> handleRequiredMeasure(e,i)}
                            style={{verticalAlign:"middle"}}
                        />                    
                    </Col>
                    
                </Row>
                
            )

        }
        return inputs
    }
    
    return (            
        <div className="d-flex justify-content-center"> 
            <Form className='w-50'>
                <fieldset>
                    <Form.Group className="mb-3">
                        <Form.Label className='float-start'>Name<span style={{color:"red"}}>*</span></Form.Label>
                        <Form.Control name='name' onChange={handleChange} type='text' value={test.name} placeholder="Test Name..."/>
                        {error.name ? <p style={{color:"red"}}>Please enter a name</p> : null}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label className='float-start'>Description<span style={{color:"red"}}>*</span></Form.Label>
                        <Form.Control name='description' onChange={handleChange} as='textarea' rows={3} value={test.description} placeholder="Test Description..."/>
                        {error.description ? <p style={{color:"red"}}>Please enter a description</p>:null}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='float-start'>Category<span style={{color:"red"}}>*</span></Form.Label>
                        <FormSelect value={test.category} name='category' onChange={handleChange} aria-label="Default select example" style={{marginBottom:".25em"}}>
                            <option value={""}>Select a type</option>
                            <option value={"Functionality"}>Functionality</option>
                            <option value={"Applicability"}>Applicability</option>
                            <option value={"Suitability"}>Suitability</option>
                            <option value={"Stability"}>Stability</option>
                            <option value={"Other"}>Other</option>
                        </FormSelect>
                        {error.category ? <p style={{color:"red"}}>Please select a category</p>:null}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label style={{marginRight:".5em"}}>This test will be used in </Form.Label>
                        <Form.Check
                            inline
                            label="Recipe"
                            type='checkbox'
                            name='application'
                            value={"recipe"}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="Ingredients"
                            type='checkbox'
                            name='ingredient'
                            value={"ingredients"}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                    <Form.Label style={{marginRight:".5em"}}>Is this test for IDG?</Form.Label>
                        <Form.Check
                            inline
                            label="Yes"
                            type='radio'
                            name='for_idg'
                            value={true}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            defaultChecked
                            label="No"
                            type='radio'
                            name='for_idg'
                            value={false}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        {renderMeasure()}
                        <Button style={{marginRight:".25em"}} onClick={handleAddMeasure} type="button" className="btn btn-primary btn-sm">Add</Button>
                        <Button onClick={handleRemoveMeasure} type="button" className='btn btn-primary btn-sm'>Remove</Button>
                        {error.measure ? <p style={{color:"red"}}>Please enter a meausure</p> : null}
                    </Form.Group>
                    <Form.Label className='float-start'>Upload Document</Form.Label>
                    <Form.Group className='mb-3'>
                        <input 
                            type='file'
                            onChange={handleFileChange}
                        />
                    </Form.Group>
                </fieldset>
            </Form>
        </div>
    )
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});

export default connect(mapStateToProps, { })(CreateTest);