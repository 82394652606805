import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import IngredientCard from './IngredientCard';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';


const AddIngredients = ({ingredients, onAddIngredient, selectAllIngrdients}) => {
    const [ingredientAmount, setIngredientAmount] = useState(0)
    const [selectedIngredients, setSelectedIngredients] = useState([])

    const renderIngredients = () => {
        let ingredientCards = []
        for(let i = 0; i < ingredientAmount; i++){
            ingredientCards.push(
                <div>
                    <IngredientCard id={i} ingredients={ingredients} onAddIngredient={addNewIngredient} selectAll={selectAllIngrdients} />
                </div>
            )
        }
        return ingredientCards
    }

    const addIngredient = () => {
        setIngredientAmount(ingredientAmount+1)
        setSelectedIngredients([...selectedIngredients, ""])
        
    }

    const removeIngredient = () => {
        setIngredientAmount(ingredientAmount-1)
        const updatedItems = [...selectedIngredients]; // Make a copy of the array
        updatedItems.pop(); // Remove the last element
        setSelectedIngredients(updatedItems);
        onAddIngredient(updatedItems)
    }
    const addNewIngredient = (ingredient,id) => {
        const updatedItems = [...selectedIngredients]; // Make a copy of the array
        updatedItems[id] = ingredient; // Modify the copy
        setSelectedIngredients(updatedItems);
        onAddIngredient(updatedItems)
    }
    
    return(
        <div>
            <Box>
                
                <div className='d-flex flex-row sm' style={{flexWrap:'wrap'}}>
                    {renderIngredients()}
                    <IconButton aria-label="Add" onClick={addIngredient} disabled={selectAllIngrdients}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton aria-label="Clear" onClick={removeIngredient} disabled={selectAllIngrdients}>
                        <ClearIcon/>
                    </IconButton>
                </div>
            </Box>
        </div>
    )

}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(AddIngredients);