import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PrivateRoute from './containers/accounts/PrivateRoute'

import Home from './containers/general/Home'
import Login from './containers/accounts/Login'
import Signup from './containers/accounts/Signup'
import Activate from './containers/accounts/Activate'
import ResetPassword from './containers/accounts/ResetPassword'
import ResetPasswordConfirm from './containers/accounts/ResetPasswordConfirm'

import Dashboard from './containers/general/Dashboard';

import SubmitMeasure from './containers/labmanager/Components/SubmitMeasure'
import SubmitTest from './containers/labmanager/Components/SubmitTest'


import { Provider } from 'react-redux';
import store from './store';

import Layout from './hocs/Layout'
import IngredientView from './containers/labvistor/IngredientView';
import TestGlossary from './containers/labvistor/TestGlossary';
import CompareIngredients from './containers/labvistor/CompareIngredients';
const App = () => (
    
    <Provider store={store}>
        <Router>
        <Layout>
        <Routes>
            {/* user auth routes PUBLIC */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
            <Route path="/activate/:uid/:token" element={<Activate />} />

            {/* LabManager routes, currently public but move to private after implementation PRIVATE */}
            <Route path="/create_test" element={<PrivateRoute><SubmitTest /></PrivateRoute>} />

            {/* general routes available after user login PRIVATE */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/create_measure" element={<PrivateRoute><SubmitMeasure /></PrivateRoute>} />
            <Route path="/:type/:uuid" element={<PrivateRoute><IngredientView /></PrivateRoute>} />
            <Route path="/test_glossary" element={<PrivateRoute><TestGlossary /></PrivateRoute>} />
            <Route path="/compare_ingredients" element={<PrivateRoute><CompareIngredients/></PrivateRoute>}/>
        </Routes>
        </Layout>
        </Router>
    </Provider>
)

export default App;