import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import CompareCharts from './CompareCharts';
import { DataGrid } from '@mui/x-data-grid';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import {TailSpin} from 'react-loader-spinner';

const CompareTable = ({access_token, ingredients, tests, measures, selectAll}) => {
    const [ingredientData, setIngredientData] = useState([])
    const [tableData, setTableData] = useState([]) // [[Chickpea, 10, 20],[...]]
    const [tableHeaders, setTableHeaders] = useState([])

    useEffect(()=>
    {
        setTableData([])
        setTableHeaders([])
        const fetchCompare  = () => 
        {
            let tempMeasures = ""
            if(measures.length > 0)
            {
                tempMeasures = []
                measures.forEach((measure) => tempMeasures.push(measure.label));
                tempMeasures = tempMeasures.join(",")
            }
            let tempTests = ""
            if(tests.length > 1)
            {
                tempTests = []
                tests.forEach((test) => tempTests.push(test.label));
                tempTests = tempTests.join(',')
            }else{
                tempTests = tests[0].label
            }
            const headers = 
            {
                'content-type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${access_token}`
            }
            const params = 
            {
                ids : ingredients.join(","),
                test_name: tempTests,
                measure_list: tempMeasures,
                select_all: selectAll
            }
            trackPromise(
                axios.get(`${process.env.REACT_APP_API_URL}/labvisitor/api/compare_ingredient_results`,{headers, params})
                    .then(response=>{
                        setIngredientData(response.data.ingredient_results)
                    })
            );
            //const response = trackPromise(await axios.get(`${process.env.REACT_APP_API_URL}/labvisitor/api/compare_ingredient_results`,{headers, params}));
            //await setIngredientData(response.data.ingredient_results)
            
        }
        if((ingredients.length != 0 || selectAll == true) && tests != "")
        {
            fetchCompare()
        }
    },[ingredients, tests, measures, selectAll])

    useEffect(()=>
    {

        if((ingredients.length != 0 || selectAll == true) && tests != "")
        {
            refactorIngredientData()
        }
    },[ingredientData])

    const refactorIngredientData = async () => 
    { //add parameter
        let tempHeaders = []
        let tempHeadersFull =[]
        if(ingredientData.length > 0)
        {
            tempHeadersFull.push({field:"Ingredient", headerName:"Ingredient", flex:2})
            tempHeadersFull.push({field: "Material", headerName:"Material #", flex:1})
        }
        let rows = []
        for(let i = 0; i < ingredientData.length; i++)
        {
            //let newIngredient = []
            let newIngredientFull = {}
            newIngredientFull['id'] = i
            newIngredientFull['Ingredient'] = ingredientData[i].name
            newIngredientFull["Material"] = ingredientData[i].material_number
            //newIngredient.push(ingredientData[i].name)
            if('measures' in ingredientData[i])
            {
                for(let j=0; j < ingredientData[i].measures.length;j++)
                {
                    if(!tempHeaders.includes(ingredientData[i].measures[j].measure_name))
                    {
                        tempHeaders.push(ingredientData[i].measures[j].measure_name)
                        tempHeadersFull.push({field:ingredientData[i].measures[j].measure_name, headerName:ingredientData[i].measures[j].measure_name, flex:1})
                    }
                    newIngredientFull[ingredientData[i].measures[j].measure_name] = ingredientData[i].measures[j].result
                }
            }
            rows.push(newIngredientFull)
        }
        await setTableData(rows);
        await setTableHeaders(tempHeadersFull)
    }
    const LoadingIndicator = props => {
        const { promiseInProgress } = usePromiseTracker();
        return( promiseInProgress && 
            (<div
                style={{
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                <TailSpin color="#0096FF" height="100" width="100" />
            </div>)
        )
    };
    return(
        <>
            <LoadingIndicator/>
            {tableData.length > 0 ? 
                <><div style={{ width: '95%', margin: "auto", borderCollapse: "collapse" }}>
                    <DataGrid
                        rows={tableData}
                        columns={tableHeaders}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 100]} />
                </div><div style={{ marginTop: '1%' }}>
                        <CompareCharts ingredientData={ingredientData} selectAll={selectAll} />
                    </div></>
            :
            null
        
            }
            
        </>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(CompareTable);
