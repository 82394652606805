import React, { useState, setState, useEffect } from 'react';
import { connect } from 'react-redux';
import ViewTest from './components/ViewTest';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { convertLength } from '@mui/material/styles/cssUtils';

const ViewTests = ({access_token, id, type}) => {
    const [tests, setTests] = useState([])
    const [testList, setTestList] = useState(["All"])
    const [selectedTest, setSelectedTest] = useState("All")
    useEffect(()=>{
        
        const retrieveResults = async () => {
            const headers = {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            }
            const params = {
                id : id,
                ingredient_formula: type,
                test_name: selectedTest,
            }
            try{
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/labvisitor/api/retrieve_results`,{headers, params});
                await setTests(response.data.test_results)
                await setTestList([...testList, ...response.data.test_names])
            }catch(error){
                console.log(error)
            }
        }
        retrieveResults();
    },[])
    useEffect(()=>{
        
        const retrieveResult = async () => {
            const headers = {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            }
            const params = {
                id : id,
                ingredient_formula: type,
                test_name: selectedTest,
            }
            try{
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/labvisitor/api/retrieve_results`,{headers, params});
                await setTests(response.data.test_results)
            }catch(error){
                console.log(error)
            }
        }
        retrieveResult();
    },[selectedTest])
    
    const optionChangeHandler = (selectedVal) => {
        setSelectedTest(selectedVal)
    } 

    const renderTests = () => {
        let inputs = []
        for(let i = 0; i < tests.length; i++){
            inputs.push(
                <ViewTest test={tests[i]}/>,
                <hr/>
            )
        }
        return(inputs)
    }
    return(
        <div>
            <div className='center mt-3 mb-3 text-center'>
                <h2>Test Data</h2>
                <div>
                    <Autocomplete
                            labelId="category-label"
                            options={testList.map((option)=>option)}
                            value={selectedTest}
                            onChange={(e,newValue) => optionChangeHandler(newValue)}
                            disableClearable={true}
                            renderInput= {(params) => (
                                <TextField
                                    {...params}
                                    label="Select Test"
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                    />
                </div>
                <hr/>
                <div>
                    {renderTests()}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(ViewTests);