import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import { reset_password_confirm } from '../../actions/auth';

const ResetPasswordConfirm = ({ match, reset_password_confirm }) => {

    const [requestSent, setRequestSent] = useState(false);

    const [formData, setFormData] = useState({
       new_password: '',
       re_new_password: ''
    });

    const { new_password, re_new_password } = formData;
    const {uid, token} = useParams();

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault();

        reset_password_confirm(uid, token, new_password, re_new_password);
        setRequestSent(true);
    }
    
    if (requestSent) {
        return <Navigate to='/' replace={true} />
    }

    return (
        <div className='container mt-5'>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group'>
                        <input 
                            className='form-control'
                            type='password'
                            name='new_password'
                            value={new_password}
                            onChange={e => onChange(e)}
                            minLength='6'
                            placeholder="New Password"
                            required
                        />
                </div>
                <div className='form-group'>
                        <input 
                            className='form-control'
                            type='password'
                            name='re_new_password'
                            value={re_new_password}
                            onChange={e => onChange(e)}
                            minLength='6'
                            placeholder="Confirm New Password"
                            required
                        />
                </div>
                <button className='btn btn-primary' type='submit'>Reset Password</button>
            </form>
        </div>
    );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);