import React, { useState, setState, useEffect, useCallback } from 'react';
import Continuous from "./Continuous";
import Categorical from './Categorical';
import Text from './Text';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';

const AddTest = ({test, onSubmitForm, errors}) => {
    const [testData, setTestData] = useState([])
    useEffect(()=>{
       var data = {
        "test_name": test.name,
        "measures":testData
       }
       onSubmitForm(data)
    },[testData])
    
    const renderMeasures = () =>{
        var inputs = []
        for(let i = 0; i < test.measures.length; i++){
            if(test.measures[i].type === "Continuous"){
                inputs.push(
                    <div style={{marginRight:".5em"}}>
                        <Continuous error={errors.measure[i]} onSubmitForm={submitForm} measure={test.measures[i]} />
                    </div>
                )
            }else if(test.measures[i].type === "Category"){
                inputs.push(
                    <div style={{marginRight:".5em"}}>
                        <Categorical error={errors.measure[i]} onSubmitForm={submitForm} measure={test.measures[i]}/>
                    </div>
                )
            }else if(test.measures[i].type === "Text"){
                inputs.push(
                    <div style={{marginRight:".5em"}}>
                        <Text onSubmitForm={submitForm} measure={test.measures[i]} error={errors.measure[i]}/>
                    </div>
                )
            }
        }
        return inputs
    }
    const submitForm = (measureValue) => {
        const selected = testData.findIndex(testData => testData.measure_name === measureValue.measure_name);
        if(selected !== -1){
            let newData = [...testData]
            newData[selected] = measureValue
            setTestData(newData)
        }else{
            setTestData([...testData, measureValue]);
        }
    }
    return(
        <div className='d-flex flex-row sm'>
            <div className='d-flex justify-content-center'>
                <div style={{marginRight:".5em"}}>
                    <h6>Test Name</h6>
                    <Box sx={{  p: 3,width:"10vw", height:"12vh"}}>
                        <h6>{test.name}</h6>
                    </Box>
                </div>
                <div style={{display:"flex", flexWrap:"wrap"}}>
                    {renderMeasures()}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(AddTest);