import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import "./TypeStyle.css"
const ViewMeasure = ({measures}) => {
    
    const renderMeasures = () =>{
        let continuous = []
        let category = []
        let text = []
        for(let i = 0; i < measures.length; i++){
            if(measures[i].measurement_type === "Continuous"){
                continuous.push(
                    <Col style={{width:"15em"}}>
                        <p>
                            <b>{measures[i].measure_name}: </b>{measures[i].result}{!measures[i].in_limit ? <span style={{color:"red"}}>*</span> : null}
                        </p>
                    </Col>
                    
                )
            }else if(measures[i].measurement_type === "Category"){
                category.push(
                    <Col style={{width:"15em"}}>
                        <p>
                            <b>{measures[i].measure_name}: </b>{measures[i].result.join()}{!measures[i].match_list ? <span style={{color:"red"}}>*</span> : null}
                        </p>
                    </Col>
                )
            }else{
                text.push(
                    <Col style={{width:"15em"}}>
                        <p><b>{measures[i].measure_name}:</b></p>
                        <p>{measures[i].result}</p>
                    </Col>
                )
            }
        }
        return (
            <Box sx={{ p: 1}}>
                <Row style={{ display: "flex", alignItems: "center", flexWrap:"wrap" }}>
                    {continuous}
                    {continuous.length > 0 ? <hr/> : null}
                </Row>
                <Row style={{ display: "flex", alignItems: "center", flexWrap:"wrap" }}>
                    {category}
                    {category.length > 0 ? <hr/> : null}
                </Row>
                <Row style={{ display: "flex", alignItems: "center", flexWrap:"wrap" }}>
                    {text}
                </Row>
            </Box>
            
        )
    } 
    return <>{renderMeasures()}</>;
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, {})(ViewMeasure);
