import axios from 'axios';
import { useState, React, useEffect } from 'react';
import CreateTest from '../CreateTest';
import Button from 'react-bootstrap/esm/Button';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';

const SubmitTest = ({isAuthenticated, access_token}) => {
    const [formData, setFormData] = useState(null)
    const [options, setOptions] = useState([])
    const [resetForm, setResetForm] = useState(false)
    const [errors, setErrors] = useState({
        "name": false,
        "description": false,
        "measures" : false,
        "category": false
    })
    useEffect(() => {
        const fetchMeasures = async () => {
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
            }
            const body = ""
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/labmanager/api/get_measures`, body ,config)
                setOptions(response.data.measures)
            }catch(error){
                console.log(error)
            }
        }
        fetchMeasures();
    }, []);
    const handleSubmit = async (e) =>{
        e.preventDefault()
        let valid = validateForm()
        if(valid){
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
            }
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/labmanager/api/create_test`, formData, config)
                Swal.fire({
                    title: "Success",
                    text: "Your test has been added",
                    icon: 'success',
                    confirmButtonText: true,
                    confirmButtonColor: '#198754',
                    showCancelButton: true,
                    cancelButtonColor: "#198754",
                    confirmButtonText: 'Go Home',
                    cancelButtonText: `Save New Test`,
                }).then((result) =>{
                    if(result.isConfirmed){
                        window.location.href = '/dashboard';
                    }else if(result.isDismissed){
                        setResetForm(true)
                    }
                    
                })
                
            }catch(error){
                console.log(error)
                Swal.fire({
                    title:"Error Adding Measure",
                    text: error.response.data.name,
                    icon:'error'
                })
            }
        }
       
        
    }
    // Where is measures used?
    const handleSubmitForm = (data) => {
        let required = []
        let optional = []
        for(let i = 0; i < data.measures.length; i++){
            if(data.required[i] == true){
                required.push(data.measures[i])
            }else{
                optional.push(data.measures[i])
            }
        }
        const formData = new FormData();
        formData.append('test', JSON.stringify({
            name: data.name,
            description: data.description,
            category: data.category,
            ingredient: data.ingredient,
            application: data.application,
            for_idg: data.for_idg,
        }));
        formData.append("measures", JSON.stringify({
            required: required,
            optional: optional
        }))
        formData.append('file', data.documents);
        setFormData(formData)
    }
    const validateForm = () => {
        let name_in, description_in, category_in = false
        let data = JSON.parse(formData.get('test'))
        if(data.name === ""){
           name_in = true
        }
        if(data.description === ""){
            description_in = true
        }
        if(data.category === ""){
            category_in = true
        }
        setErrors({...errors, ["name"]: name_in, ["description"]: description_in, ["category"]:category_in})
        if(name_in || description_in || category_in){
            return false
        }else{
            return true
        }
        
    }

    return(
        <div className='center mt-3 mb-3 text-center'>
            <h1 className="text-center pt-3">Create Test</h1>
            <CreateTest error={errors} measureOptions={options} onSubmitForm={handleSubmitForm} clearForm={resetForm} setClearForm={setResetForm}/>
            <Button style={{marginLeft:"25%"}} type="submit" onClick={handleSubmit} className="btn btn-success btn-md float-start">Submit</Button>
        </div>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(SubmitTest);