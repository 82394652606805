import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import AddIngredients from './components/FilterComponents/AddIngredients';
import axios from 'axios';
import Select from 'react-select';
import CompareTable from './components/FilterComponents/CompareTable';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
const CompareIngredients = ({access_token}) => {
    const [fullList, setFullList] = useState([])
    const [selectedIngredients, setSelectedIngredients] = useState([])
    const [tests, setTests] = useState([])
    const [measures, setMeasures] = useState([])
    const [selectTest, setSelectedTest] = useState('')
    const [selectMeasure, setSelectMeasure] = useState([])
    const [selectAllIngrdients, setSelectAllIngrdients] = useState(false)

    useEffect(()=>{
        const getIngredientList = async () =>{
            const headers = {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                'Accept': 'application/json'
            }
            try{
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/formula_ingredient/api/get_ingredients`,{headers});
                await setFullList(response.data.ingredients)
            }catch(error){
                console.log(error)
            }
        }
        const getTestList = async () => {
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
            }
            const body = ""
            try{
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/labmanager/api/get_tests`, body ,config)
                for(let i = 0; i < response.data.tests.length; i++){
                    await setTests(tests => [...tests, {value:response.data.tests[i], label:response.data.tests[i]}])
                }
            }catch(error){
                console.log(error)
            }
        }
        getTestList();
        getIngredientList();
    },[])
    useEffect(()=>{
        const getMeasuresForTest = async (test) => {
            let tempTests = ""
            if(test.length > 1){
                tempTests = []
                test.forEach((element) => tempTests.push(element.label));
                tempTests = tempTests.join(',')
            }else{
                tempTests = test[0].label
            }
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                }
            }
            const body = {
                test_names: tempTests
            }
            try{
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/labvisitor/api/get_multiple_test_measures`,JSON.stringify(body) ,config);
                let tempMeasures = []
                for(let i = 0; i < response.data.measures.length; i++)
                {
                    tempMeasures.push({value:response.data.measures[i].id, label:response.data.measures[i].name})
                }
                await setMeasures(tempMeasures)
            }catch(error){
                console.log(error)
            }
        }
        if(selectTest != "")
        {
            getMeasuresForTest(selectTest)
        }
    },[selectTest])
    const onAddIngredient = async (selectedIngredient) => {
        await setSelectedIngredients(selectedIngredient)
        //let filteredIngredients = fullList.filter(item => !selectedIngredients.includes(item));
        //setFullList(filteredIngredients)
    }

    const onRemoveIngredient = (selectedIngredient) => {
        //setFullList([...fullList, selectedIngredient])
    }
    const handleChange = (e) => {
        setSelectedTest(e)
        
    }
    const handleMeasureChange = (e) => {
        setSelectMeasure(e)
    }
    const handleSelect = (e) => {
        setSelectAllIngrdients(e.target.checked)
    }

   

    return(
        <>
            <h2 style={{ textAlign: 'center' }}>Add Ingredients to Compare</h2>
            <hr/>
            <div style={{marginBottom:'1em'}}>
                <h4 style={{marginLeft:'.5em'}}>Select Ingredients</h4>
                <FormGroup>
                    <FormControlLabel style={{marginLeft:'.25em'}} control={<Checkbox value={selectAllIngrdients} onChange={(e)=> handleSelect(e)} />} label="Select All Ingredients" />
                </FormGroup>
                <AddIngredients ingredients={fullList} onAddIngredient={onAddIngredient} onRemoveIngredient={onRemoveIngredient} selectAllIngrdients={selectAllIngrdients}/>
            </div>
            <div style={{marginBottom:'1em'}}>
                <h4 style={{marginLeft:'.5em'}}>Select a Test</h4>
                <Select 
                    options={tests}
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    size="small" 
                    onChange={(e) => handleChange(e)}
                    styles={{
                        control: (base) => ({
                        ...base,
                        width: '18em',
                        fontSize: '0.875rem',
                        marginLeft:"1em",
                        marginTop:'1em' // set the width of the control element
                      })
                    }}
                />
                <h4 style={{marginLeft:'.5em'}}>Select a Measure</h4>
                <Select
                    options={measures}
                    isClearable={true}
                    isMulti
                    isSearchable={true}
                    size="small" 
                    onChange={(e) => handleMeasureChange(e)}
                    styles={{
                        control: (base) => ({
                        ...base,
                        width: '18em',
                        fontSize: '0.875rem',
                        marginLeft:"1em",
                        marginTop:'1em' // set the width of the control element
                      })
                    }}
                
                />
            </div>
            <div>
                
                <CompareTable ingredients={selectedIngredients} tests={selectTest} measures={selectMeasure} selectAll={selectAllIngrdients}/>
            </div>
        </>
        
    )

}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});
export default connect(mapStateToProps, { })(CompareIngredients);
